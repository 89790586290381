@keyframes blinker {
  50% {
    box-shadow: 0px 0px 5px 2px rgba(0,122,255,0.3);
    border: 1px solid $blue;
  }
}

.complex-filter-group-element {
  position: relative;
  width: calc(100% - 4px);
  border: 1px solid $gray;
  background-color: $lightGray;
  border-radius: 4px;
  padding: .5rem;
  &.highlighted {
    animation: blinker 1s linear infinite;
  }
  &__content {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: .5rem;
    align-items: center;
  }
  &__bottom-row {
    margin-top: .5rem;
    &--button {
      color: $black !important;
      padding: 6px !important;
      border-radius: 17px !important;
      display: flex;
      flex-direction: row;
      gap: .5rem;
    }
  }
}
