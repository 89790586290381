.contact-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $borderBottomTableCell;
  color: $black;
  gap: .5rem;
  &.selected {
    background-color: $selectedBackground;
  }
  &__logo {
    height: 35px;
  }
  &__flex-1 {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__fix-40 {
    width: 40px;
    max-width: 40px;
  }
  &__fix-72 {
    width: 72px;
    min-width: 72px;
  }
  &__fix-100 {
    width: 100px;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    &.yes {
      color: $green;
      font-weight: bold;
    }
    &.no {
      color: $red;
      font-weight: bold;
    }
  }
  &__flex-2 {
    flex: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    .contact-link {
      color: $black;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__flex-3 {
    flex: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__flex-4 {
    flex: 4;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.contact-cell-source-container {
  display: grid;
  flex-direction: row;
  gap: .25rem;
  flex-wrap: wrap;
  grid-template-columns: repeat(4, 1fr);
  @include smallDesktop() {
    gap: .1rem;
  }
  &__item {
    @include smallText();
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $gray;
    border-radius: 50%;
    color: $white;
    padding: 5px;
    font-weight: bold;
    cursor: pointer;
    width: 25px;
    height: 25px;
    line-height: 0;
    @include smallDesktop() {
      width: 1.5vw;
      height: 1.5vw;
      font-size: .7vw;
    }
    @include tablet() {
      width: 1.2vw;
      height: 1.2vw;
    }
    @include smallTablet() {
      width: 1vw;
      height: 1vw;
    }
  }
}
