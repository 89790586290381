.contact-history-cell {
  padding: 1rem 0;
  &__date {
    padding-bottom: .5rem;
    color: $black;
    font-weight: bold;
    @include normalText();
  }
  &__container {
    .contact-history-cell-paper {
      padding: 1rem;
      &__title {
        color: $black;
        font-weight: bold;
        @include titleText();
      }
      &__description {
        color: $black;
        @include normalText();
      }
    }
  }
}
