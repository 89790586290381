.info-button-content {
  padding: .5rem;
  max-height: 350px;
  overflow-y: auto;
  &__row {
    @include smallText();
    font-weight: bold;
    color: $black;
    width: 100%;
    gap: .5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.funnel-overview-element {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  gap: 2rem;
  border-left: 1px solid $lightGray;
  padding-left: 1rem;
  &__title {
    @include titleText();
    text-transform: uppercase;
    color: black;
    min-height: 30px;
    max-height: 2rem;
  }
  &__main-indicator-box {
    .main-indicator-info-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      &__text {
        @include normalText();
        color: $black;
      }
    }
    .main-indicator-value {
      font-size: 2rem;
      color: black;
    }
  }
  &__bottom-box {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: .5rem;
    @include normalText();
    color: $black;
    min-height: 50px;
    .left-data {
      flex: 1;
    }
    .right-data {
      flex: 1;
      text-align: right;
    }
  }
}

.dropdown {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
}

.input-field {
  width: 100%;
  padding: 8px;
  margin-top: 10px;
}
