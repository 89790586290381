// Constants
@import './Styles/constants';
@import './Styles/colors';
@import './Styles/typography';
@import './Styles/mixins';

// Components
@import './Components/Components';

// Blocks
@import './Blocks/blocks';

// Modules
@import './Modules/EmailManagement/EmailManagement';
@import './Modules/Login/Login';
@import './Modules/SignUp/SignUp';
@import './Modules//Marketing/Marketing';
@import './Modules/Reports/Reports';
@import './Modules/ResetPassword/ResetPassword';
@import './Modules/UserOnboarding/UserOnboarding';

.main-content-container {
  margin-left: $drawerWidth;
}

.fixed-portal { 
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1400;
}
