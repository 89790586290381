.tag-management-container {
  color: $black;
  font-weight: bold;
  height: 100%;
  display: flex;
  flex-direction: column;
  &.in-dialogue {
    height: calc(100% - 48px);
  }
  &__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &--title {
      @include normalText();
      text-transform: uppercase;
      padding: .5rem 0;
    }
  }
  &__tag-edit-body {
    padding: .5rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: .5rem;
    min-height: 104px;
    overflow: auto;
  }
  &__search-body {
    padding: 1rem 0;
    height: calc(100% - 125px);
    display: flex;
    flex-direction: column;
    .tag-search-container {
      &__input {
        width: 100%;
      }
      &__list {
        border: solid 1px $gray;
        border-top: none;
        border-radius: 0 0 5px 5px;
        height: 100%;
      }
    }
  }
}
