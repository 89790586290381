// Components
@import './Components/Components';

// Blocks
@import './Blocks/Blocks';

.main-search-field {
  width: calc(600px + 2rem);
}

.contact-detail-container {
  padding: 1rem;
  &__top {
    padding-bottom: 1rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    &--left {
      flex: 3;
      padding-right: 2rem;
    }
    &--rigth {
      flex: 2;
    }
  }
  &__bottom {
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    border-top: 1px solid $lightGray;
    height: 48vh;
    &--left {
      flex: 1;
      height: 100%;
    }
    &--rigth {
      padding-left: 1rem;
      border-left: 1px solid $lightGray;
      flex: 1;
      height: 100%;
    }
  }
}

.contact-data-detailed-view-container {
  display: flex;
  flex-direction: row;
  &__navigation {
    width: 200px;
    box-shadow: 1px 0px 6px $lightGray;
    &--header {
      padding: 1rem 1.5rem;
      color: $black;
      text-transform: uppercase;
      border-bottom: 1px solid $gray;
      font-weight: bold;
    }
    &--items {
      padding: .5rem;
      height: calc(100vh - 150px);
      max-height: calc(100vh - 150px);
      overflow-y: auto;
      .selected-navigation-item {
        color: $blue !important;
        font-weight: bold;
        background-color: rgba(0, 122, 255, 0.05);
        margin: 0 .5rem;
      }
      .navigation-item {
        color: $black !important;
        margin: 0 .5rem;
      }
    }
  }
  &__table {
    flex: 1;
    height: calc(100vh - 100px);
    max-height: calc(100vh - 100px);
    overflow-x: auto;
  }
}
