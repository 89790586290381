.complex-filter-logical-element {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 3;
  &.in-group {
    height: 0;
  }
  &.not-in-group {
    &::after {
      position: absolute;
      top: -.5rem;
      content: '';
      width: 1px;
      height: calc(100% + 1rem);
      border-right: 1px solid $gray;
    }
  }
}
