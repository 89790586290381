.predefined-complex-filter-element {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  width: 100%;
  padding: 1rem;
  border: 1px solid $lightGray;
  &__title {
    color: $black;
    font-weight: bold;
    padding-bottom: .5rem;
    border-bottom: 1px solid $lightGray;
    @include normalText();
  }
  &__description {
    color: $black;
    padding-bottom: .5rem;
    border-bottom: 1px solid $lightGray;
    @include normalText();
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    .filter-group {
      display: flex;
      flex-direction: column;
      gap: .5rem;
      border: 1px dotted $lightGray;
      border-radius: 4px;
      padding: .5rem;
    }
    .filter-element {
      display: flex;
      flex-direction: row;
      gap: .5rem;
      color: $black;
      &__key {
        @include normalText();
      }
      &__operator {
        @include normalText();
        font-weight: bold;
      }
      &__value {
        @include normalText();
      }
    }
    .logical-element {
      color: $blue;
    }
  }
  &__bottom-actions {
    &--button {
      color: $blue !important;
      padding: 6px !important;
      border-radius: 17px !important;
      display: flex;
      flex-direction: row;
      gap: .5rem;
      @include smallText();
    }
  }
}
