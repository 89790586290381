.bizmail-dialogue-body-toolbar {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  left: 48px; // Avoiding overlap with the close button in the toolbar
  width: calc(100% - 48px);
  height: 50px;
  .toolbar-title {
    flex: 1;
    text-align: center;
    @include titleText();
    font-weight: bold;
    color: $black;
  }
}

.bizmail-dialogue-body-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  &__stepper {
    width: 100%;
  }
  &__selector {
    width: 100%;
    margin-bottom: 1rem;
  }
  &__logo-container {
    width: 50%;
    &--logo {
      margin: 0 auto;
      height: 50px;
    }
  }
}
