.grid-container {
  display: grid;
  grid-template-columns: repeat(18, 1fr);
  gap: 10px;
  padding: 20px;
  border-bottom: 1px solid $borderBottomTableCell;
  color: $black;
  width: 200rem;
}

.grid-header {
  position: sticky;
  top: 0;
  z-index: 25;
  background-color: $white;
  padding-bottom: 0.5rem;
}

.grid-bottom {
  position: sticky;
  bottom: 0;
  z-index: 25;
  padding-top: 1rem;
  background-color: $lightGray2;
}

.grid-header-content {
  font-weight: bolder;
}

.grid-scroller {
  overflow: scroll;
  height: 26rem;
  @include scrollBar();
}

.fixed-left {
  position: sticky;
  left: 1;
  background-color: $white;
  z-index: 15;
  padding-left: 0.5rem;
}

.grid-header .fixed-left {
  z-index: 30;
}

.fixed-left-2 {
  position: sticky;
  left: 9rem;
  background-color: $white;
  z-index: 15;
}

.grid-header .fixed-left-2 {
  z-index: 30;
}

.grid-bottom .fixed-left {
  background-color: $lightGray2;
}

.grid-bottom .fixed-left-2 {
  background-color: $lightGray2;

}
