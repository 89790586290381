@import './Components/FunnelCell';
@import './Components/FunnelTableHeader';

.funnel-list {
  overflow-y: scroll;
  @include scrollBar();
}

.funnel-empty-state {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $black;
}

.funnel_list {
  @include horizontalScrollBar();
  display: grid;
  overflow-x: scroll;
}

.funnel_list-component {
  overflow-y: scroll;
  overflow-x: visible;
  @include scrollBar();
}
