@import "./Blocks/SourcePathTopTable/Components/SourcePathTopCell";
@import "./Blocks/SourcePathTopTable/Components/InteractionPathButton";
@import "./Blocks/SourcePathTableTitle/SourcePathTableTitle";

.source-path-top {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;

  &__search-field {
    width: calc(600px + 2rem);
  }
}
