.cell-grid-container {
  display: grid;
  grid-template-columns: repeat(18, 1fr);
  gap: 10px;
  padding: 20px;
  border-bottom: 1px solid $borderBottomTableCell;
  color: $black;
  width: 200rem;
}

.fixed-left {
  position: sticky;
  left: 0;
  padding: 0 5px;
  background-color: $white;
}
.fixed-left-2 {
  position: sticky;
  left: 6.9rem;
  background-color: $white;
}

.funnel-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $borderBottomTableCell;
  color: $black;
  gap: .5rem;
  padding-left: 1rem;
  min-height: 57px;
  &.selected {
    background-color: $selectedBackground;
  }
  &__logo {
    height: 35px;
  }
  
  .funnel-report-cell-value {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: .5rem;
    justify-content: flex-end;
    align-items: center;
  }
}

@include tablet() {
  .funnel-cell {
    @include smallText();
  }
}

@include smallTablet() {
  .funnel-cell {
    @include verySmallText();
  }
}
