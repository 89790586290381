.percentage-presenter {
  display: inline-block;
  &__text {
    color: $black;
    display: flex;
    flex-direction: row;
    align-items: center;
    &.positive {
      color: $green;
    }
    &.negative {
      color: $red;
    }
  }
}
