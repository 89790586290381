.campaign-detail-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $black;
  border-bottom: 1px solid $borderBottomTableCell;
  gap: .5rem;
  padding-left: 1rem;
  border-left: 1px solid $borderBottomTableCell;
  border-right: 1px solid $borderBottomTableCell;
  min-height: 45px;
  background-color: $lightGray3;
  margin: 0 3px;
  &:first-child {
    box-shadow: inset 0px 5px 5px -5px rgba(0,0,0,.16);
  }
  &:last-child {
    box-shadow: inset 0px -5px 5px -5px rgba(0,0,0,.16);
  }
  &__flex-1 {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    height: 45px;
    &.number {
      justify-content: flex-end;
    }
    &.with-separator {
      border-right: 1px solid $borderBottomTableCell;
    }
  }
  &__flex-2 {
    flex: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 45px;
    &.number {
      justify-content: flex-end;
    }
    &.with-separator {
      border-right: 1px solid $borderBottomTableCell;
    }
  }
  &__fix-40 {
    max-width: 40px;
    width: 40px;
    display: flex;
    align-items: center;
  }
  .campaign-detail-cell-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    &__row {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      justify-content: space-between;
      min-height: 20px;
      &__center {
        display: flex;
        justify-content: center;
      }
      &__left {
        display: flex;
        justify-content: left;
      }
      .blue-text {
        color: $blue;
      }
    }
  }
}
