.audience-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $borderBottomTableCell;
  color: $black;
  gap: .5rem;
  padding-left: 1rem;
  min-height: 57px;
  &.selected {
    background-color: $selectedBackground;
  }
  &__logo {
    height: 35px;
  }
  &__flex-1 {
    flex: 1;
    padding-right: 22px;
    font-weight: bold;
    .contact-link {
      color: $black;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &.number {
      text-align: center;
    }
    .image-component {
      height: 35px;
      width: auto;
      display: flex;
      justify-content: flex-start;
    }
  }
  .funnel-report-cell-value {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: .5rem;
    justify-content: center;
    align-items: center;
  }
}

@include tablet() {
  .funnel-cell {
    @include smallText();
  }
}

@include smallTablet() {
  .funnel-cell {
    @include verySmallText();
  }
}
