.set-kpis-dialogue-toolbar {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;
  .toolbar-title {
    flex: 1;
    text-align: center;
    @include titleText();
    font-weight: bold;
    color: $black;
  }
}

.set-kpis-dialogue-content {
  @include scrollBar();
  height: calc(100vh - 75px);
  overflow: scroll;
  padding: 0 4px;
  &__title {
    @include titleText();
    font-weight: bold;
    color: $black;
  }
}

.set-kpis-table {
  width: 100%;
  border-spacing: 0;
  &__head {
    @include normalText();
    background-color: $lightGray2;
    color: $black;
    font-weight: bold;
    .cell {
      padding: 6px;
      width: 20%;
      &:first-child {
        padding-left: 16px;
      }
      &:last-child {
        padding-right: 16px;
      }
    }
  }
  &__body {
    .kpis-table-row {
      @include normalText();
      &:nth-child(even) {
        background-color: $lightGray2;
      }
      .cell {
        width: 20%;
        padding: 12px 6px;
        &:first-child {
          padding-left: 16px;
        }
        &:last-child {
          padding-right: 16px;
        }
      }
    }
  }
}

.kpi-action-box {
  width: 100%;
  padding: 4px 4px 8px 4px;
  text-align: center;
}
