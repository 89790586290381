.main-nav-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47px;
  height: 100%;
  color: $white;
  margin-top: 25px;
  margin-bottom: 50px;
  &.large {
    width: 12rem;
    height: 10rem;
  }
  &.normal {
    width: 4rem;
    height: 3rem;
  }
}
