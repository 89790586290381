.navigation-section {
  padding: 1rem;
  &__title {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    border-bottom: 1px solid $lightGray;
    align-items: center;
    padding: .5rem 0;
    &--icon {
      width: 30px;
      height: 30px;
      overflow: hidden;
      .image-component {
        width: 100%;
      }
    }
    &--text {
      @include titleText();
      font-weight: bold;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: .5rem;
  }
}
