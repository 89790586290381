.segment-management-filter-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &__title {
    @include smallText();
    font-weight: bold;
    color: $gray;
    text-transform: uppercase;
    display: flex;
    width: 100%;
    flex-direction: row ;
    justify-content: space-between;
  }
  &__bar {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .segment-managment-filters {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 1rem;
      &--element {
        width: 200px;
        z-index: 3;
      }
      &--element-multi {
        width: 400px;
        z-index: 3;
      }
    }
    .segment-name {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      .text-component {
        font-weight: bold;
        color: $black;
        white-space: nowrap;
      }
      .image-component {
        height: 40px;
        width: auto;
      }
      .segment-source-skeleton {
        width: 150px;
        height: 40px;
      }
    }
  }
}
