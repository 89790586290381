.tag-cell-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  &__text {
    @include normalText();
    font-weight: normal;
    flex: 1;
  }
}
