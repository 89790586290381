.static-data-presenter {
  color: $black;
  &__title {
    @include titleText();
    font-weight: bold;
    padding: .5rem 0;
    height: 50px;
    display: flex;
    align-items: flex-end;
  }
  &__sub-title {
    @include normalText();
    font-weight: bold;
    text-transform: uppercase;
    padding: .5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: .5rem;
  }
  &__content {
    padding-top: 5px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    .item {
      grid-column: span 1;
    }
  }
}
