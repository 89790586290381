.campaing-status-indicator {
  padding: 6px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  background: none;
  display: inline-flex;
  &__inner {
    margin: auto;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
    &.KPI_MET {
      background-color: $green;
    }
    &.KPI_NOT_MET {
      background-color: $red;
    }
    &.NO_KPI_SET {
      background-color: $gray;
    }
    &.INACTIVE_CAMPAIGN {
      background-color: $gray;
    }
    &.ACTIVE_CAMPAIGN {
      background-color: $blue;
    }
  }
}

.campaing-status-indicator-info-box {
  color: $black;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0 0.5rem;
  &__separator {
    width: 100%;
    height: 1px;
    background-color: $lightGray;
  }
  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
}
