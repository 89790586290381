@import './Components//SecondaryNavigationBlock';
@import './Components/SecondaryNavigationItem';

.marketing-secondary-menu-container {
  color: $black;
  &__top-section {
    padding: 1rem;
    .title {
      @include hugeTitleText();
      font-weight: bold;
    }
    .description {
      @include normalText();
    }
  }
  &__navigation-box {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
