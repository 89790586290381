.set-kpi-button {
  font-weight: bold;
  color: $orange !important; // Important is needed to override MUI style just for this case
  &:hover {
    text-decoration: underline;
  }
  &.disabled {
    color: #C6C6C6 !important;
  }
  &.is-red {
    color: $red !important;
  }
  &.is-orange {
    color: $orange !important;
  }
  &.is-blue {
    color: $blue !important;
  }
}
