@import './Components/ComplexFilterElement';
@import './Components/ComplexFilterGroupElement';
@import './Components/ComplexFilterSetPresenter';
@import './Components//ComplexFilterElement';
@import './Components/ComplexFilterLogicalElement';

.complex-filter-dialogue-toolbar {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;
  .toolbar-title {
    flex: 1;
    text-align: center;
    @include titleText();
    font-weight: bold;
    color: $black;
  }
}

.complex-filter-text-editor {
  padding: .5rem 0;
}

.complex-filter-dialogue-content {
  height: calc(100vh - 180px);
  overflow-y: auto;
  padding-top: 1rem;
  &__bottom-row {
    margin-top: .5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &--button {
      color: $black !important;
      padding: 6px !important;
      border-radius: 17px !important;
      display: flex;
      flex-direction: row;
      gap: .5rem;
    }
  }
}

.complex-filter-dialogue-bottom {
  display: flex;
  justify-content: center;
  padding-top: .5rem;
  border-top: 1px solid $lightGray;
}
