.table-footer {
  background-color: $lightGray2;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid $borderBottomTableCell;
  color: $black;
  min-height: 57px;
  gap: .5rem;
  padding-right: 16px;
  &__checkbox-cell {
    width: 72px;
    min-width: 72px;
    .cell-item {
      display: inline;
    }
  }
  .table-footer-cell-with-percentage {
    display: flex;
    gap: .5rem;
    align-items: center;
    justify-content: flex-end;
  }
  &__flex-1 {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 22px;
  }
  &__fix-40 {
    max-width: 40px;
    width: 40px;
  }
  &__fix-100 {
    max-width: 100px;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__flex-2 {
    flex: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 57px;
    display: flex;
    align-items: center;
  }
  &__flex-3 {
    flex: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 57px;
    display: flex;
    align-items: center;
  }
  &__flex-4 {
    flex: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 57px;
    display: flex;
    align-items: center;
  }
  &__actions {
    display: flex;
    flex-direction: row;
  }
}

@include tablet() {
  .table-footer {
    @include smallText();
  }
}

@include smallTablet() {
  .table-footer {
    @include verySmallText();
  }
}
