.navigation-bar-container {
  display: flex;
  flex-direction: row;
  color: $black;
  align-items: center;
  gap: 1rem;
  &__title {
    @include titleText();
    font-weight: bold;
    flex: 1;
    text-align: center;
    &.left-aligned {
      text-align: left;
    }
  }
  &__vertical-line {
    background-color: $lightGray;
    width: 1px;
    height: 30px;
  }
}
