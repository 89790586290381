.date-range-selector-paper {
  display: flex;
  flex-direction: row;
  padding: 0 8px;
  &__container {
    display: flex;
    align-items: center;
    height: 39px;
  }
  &__button-placeholder {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
