.nav-link {
  @include smallText();
  text-decoration: none;
  font-weight: bold;
  color: $black;
  width: 100%;
  border-left: solid 4px $white;
  color:$gray;
  &--active {
    color: $black;
    border-left: solid 4px $primaryColor;
  }
  &__list-item {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    &--text {
      margin-top: 5px;
      text-decoration: none;
      text-align: center;
    }
  }
}
