.campaign-list {
  @include scrollBar();
  overflow-y: scroll;
  &__header-wrapper {
    overflow: hidden;
    padding: 8px 20px 0 4px;
  }
  &__header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: $white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);
  }
  &__footer-wrapper {
    overflow: hidden;
    padding: 0 20px 8px 4px;
  }
  &__footer {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);
    background-color: $white;
  }
}
