.info-button-arrow {
  background-color: $white;
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  top: calc(50% - 6px);
  transform: rotate(45deg);
  left: -6px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0,  0.16);
}

// Importants are needed to override the base MUI styling
.info-button-popover {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.16), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.08);
  overflow: initial !important;
  border-radius: 1rem !important;
  padding: 1rem 0.5rem !important;
}
