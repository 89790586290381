.pop-up-dialog-image {
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.dialog-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pop-up-dialog-title {
    text-align: center;
    font-family: 'Roboto';
    font-size: 20px;
}

.pop-up-dialog-content {
    color: black;
    font-family: 'Roboto';
    font-size: 16px;
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: center;
    padding: 1rem;
    line-height: 1.8;
      span {color: green}
      label {color: red}
}

.pop-up-button-input {
  margin: 1rem 0;
  width: 70%;
}

.pop-up-button {
  margin-bottom: 1rem;
  justify-content: center;
  align-items: center;
  display: flex;
}
