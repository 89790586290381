.source-path-top-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $borderBottomTableCell;
  color: $black;
  gap: .5rem;
  padding-left: 1rem;
  min-height: 57px;
  padding-right: 16px;
  &.selected {
    background-color: $selectedBackground;
  }
  &__flex-1 {
    flex: 1;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 1rem;
    &.more-padding {
      padding-left: 1.5rem;
    }
    &.less-padding {
      padding-left: 0.5rem;
    }
  }
  &__flex-2 {
    flex: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    display: flex;
    align-items: center;
    &__text {
      max-width: 20rem;
      overflow: hidden;
      .contact-link {
        color: $black;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &__flex-4 {
    flex: 4;
    overflow-x: scroll;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    font-weight: bold;
    @include horizontalScrollBar();
  }
}

.source-path-top__path-button{
  display: flex;
  align-items: center;
}

@include tablet() {
  .source-path-top-cell {
    @include smallText();
  }
}

@include smallTablet() {
  .source-path-top-cell {
    @include verySmallText();
  }
}
