.complex-filter-bar-element {
  padding-left: 12px !important;
  border-radius: 17px !important;
  color: $black;
  border: 1px solid $gray !important;
  display: flex;
  flex-direction: row;
  gap: .5rem;
  align-items: center;
  transition: border-color .15s ease-in-out;
  transition: background-color .15s ease-in-out;
  cursor: pointer;
  &__key {
    color: $black;
  }
  &__operator {
    color: $black;
  }
  &__value {
    color: $black;
    font-weight: 700;
  }
}
