.image-presenter-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &__placeholder {
    width: 100%;
    height: 100%;
    background-color: lightGray;
  }
  &__image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    &.hidden-image {
      position: absolute;
      opacity: 0;
    }
  }
}
