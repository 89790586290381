.tag-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  gap: .5rem;
  position: relative;
  overflow-x: scroll;
  @include horizontalScrollBar();
  &__layer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 80%;
    right: 0;
    &.selected {
      background: none;
      // background: linear-gradient(90deg, rgba(0,0,0,0) 0%, $selectedBackground 60%, $selectedBackground 100%);
      // mask-image: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(255,255,255,0) 60%, selectedBackground 100%);
      // mask-image: linear-gradient(to right, black 50%, transparent 100%);
      // mask-border-mode: linear-gradient(to bottom, black 50%, transparent 100%);
      
    }
  }
}
