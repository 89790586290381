.tagmanagement-dialogue-body-toolbar {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  left: 48px; // Avoiding overlap with the close button in the toolbar
  width: calc(100% - 48px);
  height: 50px;
  .toolbar-title {
    flex: 1;
    text-align: center;
    @include titleText();
    font-weight: bold;
    color: $black;
  }
}
