.funnel-overview-section {
  padding: 1rem;
  margin: 12px 0;
  color: $black;
  min-height: 283px;
  &__title {
    @include smallText();
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: .5rem;
  }
  &__content {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
}
