.compare-dialogue-toolbar {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;
  .toolbar-title {
    flex: 1;
    text-align: center;
    @include titleText();
    font-weight: bold;
    color: $black;
  }
}

.compare-dialogue-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  &__title {
    margin-top: 1.5rem;
    width: 90%;
    color: $black;
    text-transform: uppercase;
    font-weight: bold;
    @include smallText();
  }
  &__top {
    width: 90%;
    color: $black;
    @include normalText();
    border-bottom: 1px solid $lightGray;
    padding-bottom: .5rem;
  }
  &__bottom {
    width: 90%;
    .compare-dialogue-radio-button {
      justify-content: space-between;
      margin-left: 0;
      span {
        font-weight: bold;
      }
      &.selected {
        span {
          color: $blue;
        }
      }
    }
  }
  &__actions {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
}
