// Minimum dialog width and centering content
.dialog-content {
  &-title {
    text-align: center;
  }
  &-data_fields {
    width: 100%;
    margin-top: 0.5rem;
    &-inputs {
      margin-top: 1rem;
    }
  }
}

// Centering the dialog buttons
.dialog-actions {
  justify-content: center !important;
  .action-button {
    margin-bottom: 0.5rem;
  }
}
