@import './Components/PredefinedComplexFilterElement';

.predefined-complex-filter-dialogue-toolbar {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  left: 48px; // Avoiding overlap with the close button in the toolbar
  width: calc(100% - 48px);
  height: 50px;
  .toolbar-title {
    flex: 1;
    text-align: center;
    @include titleText();
    font-weight: bold;
    color: $black;
  }
}

.predefined-complex-filter-dialogue-content {
  height: calc(100vh - 150px);
  overflow-y: auto;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  &__bottom-row {
    margin-top: .5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &--button {
      color: $black !important;
      padding: 6px !important;
      border-radius: 17px !important;
      display: flex;
      flex-direction: row;
      gap: .5rem;
    }
  }
}
