.contact-data-detailed-list-table-header {
  &__item {
    text-transform: uppercase;
    color: $black;
    font-weight: bold !important;
    padding: 1rem 1.5rem !important;
    line-height: unset !important;
    white-space: nowrap;
    background-color: $white !important;
  }
}

.contact-data-detailed-list-table-body {
  &__row {
    &--item {
      padding: 1rem 1.5rem !important;
    }
  }
}
