.email-management-filter-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &__title {
    @include smallText();
    font-weight: bold;
    color: $gray;
    text-transform: uppercase;
  }
  &__filter-list {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
    &--element {
      width: 200px;
      z-index: 3;
    }
    &--element-multi {
      width: 400px;
      z-index: 3;
    }
  }
}
