@import './Components/DataElementWithSelectOptions';
@import './Components/DataTab';

.data-presenter {
  color: $black;
  &__selector {
    border-bottom: 1px solid $lightGray;
    height: 50px;
    display: flex;
    align-items: flex-end;
  }
  &__content {
    &--title {
      @include normalText();
      font-weight: bold;
      text-transform: uppercase;
      padding: .5rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.5rem;
    }
    &--container {
      padding-top: 5px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 1rem;
      min-height: 60px;
      height: 23vh;
      overflow: auto;
      align-content: baseline;
      .selector-component {
        z-index: 2;
        grid-column: span 1;
      }
      .data-link {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: $blue;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
