.source-path__table-title {
  @include titleText();
  color: $black;
  font-weight: bold;
  margin: 0.5rem 0;
}

.source-path__table-title__indicator {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.16), 0px 4px 5px 0px rgba(0, 0, 0, 0.1), 0px 1px 10px 0px rgba(0, 0, 0, 0.08);
  overflow: initial !important;
  border-radius: 1rem !important;
  padding: 0.5rem !important;
}
