@import './Components/ContactHistoryCell';

.contact-history-presenter {
  color: $black;
  height: 100%;
  &__title {
    @include normalText();
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__content {
    height: calc(100% - 1rem);
    overflow-y: auto;
    &--no-content {
      color: $gray;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 85%;
      font-size: 70px;
      .no-content-text {
        width: 40%;
        text-align: center;
        @include titleText();
        font-weight: bold;
      }
    }
  }
}
