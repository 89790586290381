@mixin scrollBar {
  &::-webkit-scrollbar {
    appearance: none;
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 16px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 4px solid white;
    background-color: $gray;
  }

  &::-webkit-scrollbar-track { 
    background-color: #fff; 
    border-radius: 8px; 
  }
}

@mixin horizontalScrollBar {
  &::-webkit-scrollbar {
    appearance: none;
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:horizontal {
    height: 25px;
  }

  &::-webkit-scrollbar-thumb {
    border: 11px solid white;
    background-color: $gray;
  }
  
  &::-webkit-scrollbar-track { 
    background-color: #fff;
  }
}

// Media query mixins
@mixin smallDesktop {
  @media screen and (max-width: $smallDesktop) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: $tablet) {
    @content;
  }
}

@mixin smallTablet {
  @media screen and (max-width: $smallTablet) {
    @content;
  }
}
