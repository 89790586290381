.custom-selector {
  &__control {
    border-style: none !important; // Needed to override react-select base style
    height: 100%;
  }
  &__select-container {
    position: relative;
  }
}

.placeholder-box {
  @include normalText();
  text-transform: capitalize;
  color: $black;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.css-u275wx-option {
  color: $black !important;
}
