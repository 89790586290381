.interaction-path-button {
  border: 2px;
  border-style: solid;
  border-radius: 4px;
  &__text{
    padding: 0.2rem 0.5rem;
    white-space: nowrap;
  }
}

.interaction-path-icon {
  margin: 0 0.8rem;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 12px;
  border-color: transparent transparent transparent lightgray;
}
