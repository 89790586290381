@import "./Blocks/CampaignList/CampaignList";
@import "./Blocks/CampaignList/CampaignCell";
@import "./Blocks/CampaignList/SetKPIButton";
@import "./Blocks/CampaignList/CampaignDetailCell";
@import "./Blocks/CampaignList/CampaignStatusIndicator";
@import "./Blocks/SetKpisDialogue/SetKpisDialogue";

.campaigns-toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin-bottom: 12px;
  &__group-separator {
    background-color: $gray;
    width: 1px;
    height: 42px;
    position: relative;
    top: 8px;
  }
  &__group {
    .action-title {
      @include smallText();
      font-weight: bold;
      color: $gray;
      text-transform: uppercase;
    }
    .campaigns-tab {
      margin-left: 0.5rem;
    }
  }
}
