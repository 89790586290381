@import "./Blocks/AudiencesPerformanceList/Components/AudienceIndicatorElement";
@import "./Blocks/AudiencesPerformanceList/Components/AudienceCell";

.audiences-performance-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
  &__button {
    background-color: $blue !important;
    color: $white !important;
    padding: 6px 12px 6px 12px !important;
    border-radius: 17px !important;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    height: 32px;
  }
}
