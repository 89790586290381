.module-container {
  max-width: $moduleContainerWidth;
  margin: 0 auto;
  padding: 0 1rem 2rem 1rem;
  &__avatar {
    font-size: 1rem !important; // Important is needed to overide text size just for the avatar icon
  }
  &__toolbar {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    min-height: 64px;
    padding-top: 12px;
    &--content {
      flex: 1;
    }
    &--avatar-holder {
      display: flex;
      justify-content: flex-end;
      width: 60px;
    }
  }
}
