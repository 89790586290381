.half-page-dialogue-container {
  padding: 1rem;
  height: 100%;
  &__close {
    z-index: 1000;
  }
  &__body {
    height: 100%;
    position: relative;
    top: -48px;
  }
}
