@import './Components/ComplexFilterBarElement';

.complex-filter-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &__title {
    @include smallText();
    font-weight: bold;
    color: $gray;
    text-transform: uppercase;
  }
  &__filter-list {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: .5rem;
    flex-wrap: wrap;
    &:hover {
      .filter-group:hover {
        opacity: 1;
        .complex-filter-bar-element {
          border-color: $black;
          background-color: $white;
        }
      }
      .filter-group:not(:hover) {
        opacity: 0.4;
      }
    }
    .filter-group {
      display: flex;
      flex-direction: row;
      gap: .5rem;
      flex-wrap: wrap;
      background-color: $white;
      opacity: 0.8;
      transition: opacity .15s ease-in-out;
    }
    &--add-button {
      background-color: $blue !important;
      color: $white !important;
      padding: 6px 6px 6px 12px !important;
      border-radius: 17px !important;
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }
    &--clear-button {
      padding: 6px !important;
      border-radius: 17px !important;
      color: $black !important;
    }
  }
}
