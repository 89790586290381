.login-screen-container {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__paper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    min-width: 350px;
    &-title {
      font-weight: bold;
      font-size: 1.2rem;
    }
    &-top-text-box {
      text-align: center;
      max-width: 350px;
    }
    &-inputfield {
      margin-top: 1rem;
      width: 100%;
    }
    &-button {
      width: 100%;
      margin-top: 1rem;
    }
    &-forgot-password {
      width: 100%;
      margin-top: 10px;
      text-align: center;
      &-link {
        color: #007bff;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
