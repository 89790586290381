.sync-dialog-top {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  &-card {
    border: 2px solid #DFDFDF;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(235, 222, 222, 0.1);
    width: 100px;
    text-align: center;
    margin: 0 0.5rem;
    display: flex;
    flex-direction: column;
    &.sucess {
      border: 2px solid $green;
    }
    &.selected {
      border: 2px solid $primaryColor;
    }
  }
  &__icon {
    width: 100%;
    text-align: center;
    margin-top: 0.5rem;
    &.disabled {
      opacity: 0.5;
    }
  }
  &__checkbox {
    display: flex;
    margin-top: auto;
    justify-content: center;
  }
}

.sync-dialog-head {
  display: flex;
  justify-content: center;
  &-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 2rem;
    &-position {
      max-width: 9rem;
      margin-top: 1rem;
      text-align: center;
    }
  }
  &-title {
    margin-top: 1rem;
    text-align: center;
    width: 60%;
  }
  &-button {
    display: flex;
    justify-content: flex-end;
  }
}

.sync-dialog-content {
  text-align: center;
  &-top_text {
    width: 100%;
  }
  &-top_value {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

.sync-dialog-input {
  display: flex;
  justify-content: center;
}

.sync-dialog-bottom {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.sync-dialog-title {
  text-align: center;
  width: 100%;
}

