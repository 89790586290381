.sign-up-screen-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__paper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    min-width: 350px;
    &-title {
      font-weight: bold;
      font-size: 1.2rem;
    }
    &-inputfield {
      margin-top: 1rem;
      width: 100%;
    }
    &-radiofield {
      margin-top: 1rem;
      width: 100%;
      text-align: center;
    }
    &-button {
      width: 100%;
      margin-top: 1rem;
    }
  }
}

.bottom-text-box {
  text-align: center;
  max-width: 20rem;
}

.link-button {
  background: none;
  color: $blue;
  text-decoration: underline;
  border: none;
  cursor: pointer;
  padding: 0;
  font: inherit;
}
