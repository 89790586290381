.complex-filter-element {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  width: 100%;
  &:not(:first-child) {
    position: relative;
    top: -.7rem;
  }
  &__top-bar {
    text-align: right;
  }
  &__key-operator-row {
    display: flex;
    gap: 0.5rem;
    .item {
      flex: 1;
      background-color: $white;
      border-radius: 4px;
    }
  }
  &__value-row {
    background-color: $white;
    border-radius: 4px;
    .boolean-input {
      width: 100%;
    }
    .selector-input {
      width: 100%;
      .is-single {
        max-height: 36px;
      }
    }
  }
}
