.activate-dialog {
  min-width: 500px;
  .search-field-container {
    padding: 0 1rem;
  }
  .activate-search-field {
    width: 100%
  }

  .radio-list-container {
    padding: 20px;
    height: 15rem;
    overflow: scroll;
    .radio-list {
      text-align: left;
      list-style-type: none;
      padding-left: 0;

      li {
        margin-bottom: 10px;
      }

      label {
        font-size: 14px;
      }
    }
  }

  .link-button {
    margin-top: 10px;
    text-decoration: underline;
    color: #1976d2;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;

    &:hover {
      text-decoration: none;
    }
  }

  .pop-up-button {
    display: flex;
    justify-content: space-between;

    .action-button {
      flex: 1;
      margin: 0 5px;
    }
  }
}
