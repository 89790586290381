@mixin chipContainerBase {
  display: flex;
  align-items: center;
  border: 1px solid $gray;
  border-radius: 19px;
  padding: 4px 4px 4px 8px;
  @include normalText();
  color: $black;
  font-weight: normal;
  cursor: pointer;
  height: 40px;
  transition: background-color .1s, border-color .1s, color 1s;
}

.tag-chip-container {
  @include chipContainerBase();
  &--added {
    @include chipContainerBase();
    border-color: $green;
    color: $green;
    background-color: $lightGreen;
  }
  &--removed {
    @include chipContainerBase();
    border-color: $red;
    color: $red;
    text-decoration: line-through;
    background-color: $lightRed;
  }
}
