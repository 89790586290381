.contact-list-toolbar-container {
  min-height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  &__separator {
    border-right: solid 1px $lightGray;
    height: 30px;
  }
}
