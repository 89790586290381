.user-onboarding-screen-container {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  &__paper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    width: 600px;
    &-title {
      font-weight: bold;
      font-size: 1.2rem;
    }
    &-components {
      margin-top: 1rem;
      width: 100%;
      text-align: center;
    }
    &-button {
      width: 50%;
      margin-top: 1rem;
    }
  }
}

.box-container {
  border: 1px solid $gray;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  .box-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-check_icon {
      margin-right: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &-text {
        color: green;
        margin-left: 0.5rem;
        font-size: 16px;
        
      }
    }

    .left-img {
      height: 100%; // Igény szerint állítható
    }

    .right-button {
      margin-left: auto;
      width: 160px; // A gomb szélesítése
    }
  }

  .box-footer {
    display: flex;
    justify-content: right;
    margin-top: 0.5rem;
    align-items: center;
    flex-direction: row;

    .email-icon {
      margin-right: 4px;
      color: $gray
    }

    span {
      font-size: 12px;
      color: $black;
    }
  }
  .divider {
    border: none;
    border-top: 1px solid $gray;
    margin: 16px 0;
    width: 100%;
    align-self: center;
  }
}

.user-onboarding-screen-container-security__paper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 600px;
  margin-top: 1rem;
  padding: 16px; // A Paper belső térköze
  &-components {
    margin-top: 1rem;
    width: 100%;
    text-align: center;
    .feature-item {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        margin-left: 5px;
      }
    }
  }
}

.link-button {
  text-decoration: none;
  cursor: pointer;
}
