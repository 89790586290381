@import './Components/SegmentCell';

.segment-contacts-empty-state {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $black;
}
