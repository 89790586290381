.text-editor {
  border-radius: 4px;
  border: solid 1px transparent;
  &.error {
    border: dotted 1px $red;
  }
  &:hover {
    border: dotted 1px $lightGray;
  }
}
