.secondary-navigation-item {
  @include normalText();
  color: $black;
  display: flex;
  flex-direction: row;
  gap: .5rem;
  padding: 0.25rem;
  align-items: center;
  &__active-idicator {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    border: solid 1px $blue;
    &.active {
      background-color: $blue;
    }
  }
  &__text {
    color: $black;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  &__active-text {
    font-weight: bold;
  }
}
