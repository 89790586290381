$blue: #007AFF;
$white: #fff;
$gray: #C8C8C9;
$lightGray: #E8E8E8;
$lightGray2: #FAFAFA;
$lightGray3: #FAFBFC;
$black: #707070;
$green: #00D153;
$lightGreen: #E5FBED;
$red: #FF0000;
$orange: #FF9300;
$lightRed: #FFE5E5;
$selectedBackground: #007aff14;
$borderBottomTableCell: rgb(224, 224, 224);

$primaryColor: $blue;
