@mixin verySmallText {
  font-size: 0.625rem; // 10px
}

@mixin smallText {
  font-size: 0.75rem; // 12px
}

@mixin normalText {
  font-size: 0.875rem; // 14px
}

@mixin titleText {
  font-size: 1rem; // 16px
}

@mixin hugeTitleText {
  font-size: 1.5rem; // 24px
}
