.table-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $borderBottomTableCell;
  color: $black;
  min-height: 57px;
  display: flex;
  align-items: center;
  gap: .5rem;
  padding-right: 16px;
  &__checkbox-cell {
    width: 72px;
    min-width: 72px;
    min-height: 57px;
    display: flex;
    align-items: center;
    .cell-item {
      display: inline;
    }
  }
  &__flex-1 {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 57px;
    display: flex;
    align-items: center;
  }
  &__fix-40 {
    max-width: 40px;
    width: 40px;
    min-height: 57px;
    display: flex;
    align-items: center;
  }
  &__fix-100 {
    max-width: 100px;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 57px;
    display: flex;
    align-items: center;
  }
  &__flex-2 {
    flex: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 57px;
    display: flex;
    align-items: center;
  }
  &__flex-3 {
    flex: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 57px;
    display: flex;
    align-items: center;
  }
  &__flex-4 {
    flex: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 57px;
    display: flex;
    align-items: center;
  }
  &__actions {
    display: flex;
    flex-direction: row;
    min-height: 57px;
    display: flex;
    align-items: center;
  }
  .with-separator {
    border-right: 1px solid $borderBottomTableCell;
    min-height: 57px;
    display: flex;
    align-items: center;
  }
}

@include tablet() {
  .table-header {
    @include smallText();
    &__flex-1 {
      overflow: visible;
    }
  }
}

@include smallTablet() {
  .table-header {
    @include verySmallText();
    &__flex-1 {
      overflow: visible;
    }
  }
}
