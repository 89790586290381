.funnel-table-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $borderBottomTableCell;
  color: $black;
  min-height: 57px;
  gap: .5rem;
  padding-right: 16px;
  padding-left: 1rem;
  &__flex-1 {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
