.campaign-cell-wrapper {
  overflow: hidden;
  padding: 0 4px;
}

.campaign-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $black;
  border-bottom: 1px solid $borderBottomTableCell;
  gap: .5rem;
  padding-left: 1rem;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.16);
  &__flex-1 {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    &.number {
      justify-content: flex-end;
    }
    &.with-separator {
      border-right: 1px solid $borderBottomTableCell;
    }
  }
  &__flex-2 {
    flex: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    align-items: center;
    &.number {
      justify-content: flex-end;
    }
    &.with-separator {
      border-right: 1px solid $borderBottomTableCell;
    }
  }
  &__fix-40 {
    max-width: 40px;
    width: 40px;
    display: flex;
    align-items: center;
  }
  background-color: $white;
  min-height: 59px;
  align-items: stretch;
  .campaign-cell-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    &__row {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      justify-content: space-between;
      min-height: 20px;
      &__center { 
        display: flex;
        justify-content: center;
      }
      &__left { 
        display: flex;
        justify-content: left;
      }
      .blue-text {
        color: $blue;
      }
    }
  }
}

.campaign-cell-dropdown-icon {
  transform: rotate(0deg);
  transition: transform .15s ease-in-out;
  &.open {
    transform: rotate(180deg);
    transition: transform .15s ease-in-out;
  }
}

.campaign-cell-detail-box {
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.15s ease-in-out;
  &.open {
    max-height: 500px;
    overflow-y: hidden;
    transition: max-height  0.15s ease-in-out;
  }
}
